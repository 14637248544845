<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="일상점검 계획 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn v-if="editable && !disabled && isRemove" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable && !disabled && !data.sysApprovalRequestId"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-process
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  label="공정"
                  name="processCd"
                  v-model="data.processCd"
                  @datachange="datachange('A')"
                  @processName="getProcessName">
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="점검년월"
                  type="month"
                  name="checkDate"
                  v-model="data.checkDate"
                  @datachange="datachange('B')">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  :plantCd="data.plantCd"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="점검부서"
                  name="checkDeptCd"
                  v-model="data.checkDeptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :data="data"
                :grid.sync="grid"
                :contentHeight="contentHeight"
                @getDetail="getDetail"
                @setKey="setKey"
              />
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        dailyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      year: '',
      grid: {
        merge: [
          { index: 0, colName: 'equipmentCd' },
        ],
        columns: [],
        data: [],
        height: 'auto',
      },
      data: {
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        processCd: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        dailyImproveModelList: [], // 개선 list
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      tab: 'equipmentDailyResult',
      tabItems: [
        { name: 'equipmentDailyResult', icon: 'list', label: '일상점검 결과', component: () => import(`${'./equipmentDailyResult.vue'}`) },
        { name: 'equipmentDailyImpr', icon: 'health_and_safety', label: '개선관리', component: () => import(`${'./equipmentDailyImpr.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.checkStatusCd && this.data.checkStatusCd !== 'MCSC000015', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000003', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          dailyCheckId: this.data.dailyCheckId,
          isApprContent: true
        },
        approvalRequestName: '일상점검 ['+this.data.dailyName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.dailyCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    isRemove() {
      return this.popupParam.dailyCheckId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 240);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.getUrl = selectConfig.sop.min.equipment.plan.daily.get.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.plan.daily.update.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.daily.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.daily.complete.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    setKey(data) {
      this.popupParam.dailyCheckId = data
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.dailyCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.dailyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.lineCd = this.data.lineCd;
          this.setColumns();
        },);
      }
    },
    saveData() {
      if (this.popupParam.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.data.dailyResultModelList = this.$_.clone(this.grid.data)

              if (this.mappingType === 'POST') {
                this.$_.forEach(this.data.dailyResultModelList, _item => {
                  _item.editFlag = 'C';
                })
              }

              this.data.utilityFlag = 'N';

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.dailyCheckId = result.data
      this.getDetail();
    },
    datachange(type) {
      if (type === 'A' && this.data.processCd) {
        this.$http.url = this.$format(this.resultItemDetail, this.data.processCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.dailyResultModelList = _result.data;
          this.$_.forEach(this.data.dailyResultModelList, _item => {
            _item.dailyCheckResultId = uid()
          })
          this.setColumns();
        },);
      }
      else if (type === 'B' && this.data.checkDate) {
        // this.grid.data = [];
        this.setColumns();
      }
    },
    setColumns() {
      let _columns = [
        {
          name: 'equipmentName',
          field: 'equipmentName',
          label: '설비명',
          align: 'center',
          style: 'width:220px',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '부품명',
          align: 'center',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkItemMethod',
          field: 'checkItemMethod',
          label: '점검방법',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkStandard',
          field: 'checkStandard',
          label: '점검기준',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          label: '주기',
          align: 'center',
          sortable: false,
          child: [
            {
              name: 'cycle1',
              field: 'cycle1',
              label: '일',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle2',
              field: 'cycle2',
              label: '주',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle3',
              field: 'cycle3',
              label: '월',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
          ]
        },
      ]
      let days = [];
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      // 주말 계산
      let date = new Date(Number(year), Number(month), 0);
      var week = ['일', '월', '화', '수', '목', '금', '토'];

      // 양력 공휴일 계산
      var solorHolidays = [ "01-01", "03-01", "05-05", "06-06", "07-17", "08-15", "10-03", "12-25"]; 

      if (this.data.checkDate) {
        this.$http.url = this.$format(this.lunarList, year);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 음력 공휴일 계산은 백엔드에서
          this.lunarHolidays = _result.data

          for (let i=1; i<=date.getDate(); i++) {
            var dayOfWeek = week[new Date(Number(year) + '-' + Number(month) + '-' + i).getDay()];

            var solarIdx = 0;
            let countDay = 0;
            if (i < 10) {
              countDay = '0' + i
              solarIdx = solorHolidays.indexOf(month + '-0' + i);
            } else {
              countDay = i
              solarIdx = solorHolidays.indexOf(month + '-' + i);
            }
            var lunarIdx = 0;
            if (i < 10) {
              lunarIdx = this.lunarHolidays.indexOf(year + month + '0' + i);
            } else {
              lunarIdx = this.lunarHolidays.indexOf(year + month + i);
            }
            
            var day = '';
            if (dayOfWeek === '일' || dayOfWeek === '토' || solarIdx >= 0 || lunarIdx >= 0) {
              day = 'weekend'
            } else {
              day = 'weekday'
            }
            days.push({
              name: 'day' + i + 'Name',
              field: 'day' + i + 'Name',
              key: 'day' + i,
              label: i,
              currentDay: String(countDay) === String(this.today) && String(month) === String(this.currentMonth) 
                        ? true : false, 
              align: 'center',
              size: 'xs',
              setHeader: true,
              style: 'width:50px',
              // dayType: day,
              headerColor: day === 'weekend' ? '#FFCDD2' : '',
              type: 'custom',
              headType: 'link',
              sortable: false,
              // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
            })
          }
          this.grid.columns = this.$_.concat(_columns, {
            name: 'year',
            field: 'year',
            label: '측정값 ( 진동 m/S2). ( 온도  ℃  ). ( 전류  A  ) .',
            child: days
          })
          this.grid.data = this.$_.clone(this.data.dailyResultModelList)
        },);
      }
      
          
      if (this.data.checkDate && this.data.processCd) {
        this.data.dailyName = year + '년도  ' + month + '월  '
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.data.dailyResultModelList = this.$_.clone(this.grid.data)
          this.data.utilityFlag = 'N';
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    getProcessName(data) {
      this.processName = data;
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.dailyCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
  }
};
</script>
